<template>
  <div class="page">
    <div class="top">
        <div class="item">
        <div class="item-top">
            <div class="title">脱敏设置</div>
            <div class="tips">设置在系统内电话号码、证件号码显示形式。</div>
        </div>
        <div class="item-mid">
            <van-switch v-model="isDesensitizer"></van-switch>
            <div class="item-name">脱敏</div>
        </div>
        <div class="item-down">
            <div>电话号码、证件号码不会显示完整</div>
            <div>例如：123 **** 2154、41**** **** **** **36</div>
        </div>
        </div>
        <div class="item">
        <div class="item-top">
            <div class="title">接收网格/社区消息设置</div>
            <div class="tips">设置是否网格果果端接收消息提醒。</div>
        </div>
        <div class="item-mid">
            <van-switch v-model="isReceive"></van-switch>
            <div class="item-name">接收</div>
        </div>
        <div class="item-down">
            <div>网格果果端将不接收自己所在社区数据更新消息</div>
        </div>
        </div>
    </div>
    <div class="down">
        <van-button class="button" @click="confirm">保存</van-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
    data() {
        return {
            isDesensitizer: false,
            isReceive: false,
        }
    },
    created() {
        if(localStorage.getItem('isDesensitizer') == 0) {
            this.isDesensitizer = false
        }else {
            this.isDesensitizer = true
        }
        this.$http({
            url: this.$http.adornUrl('/wxapp/sys/user/getWarn'),
            method: 'GET',
        }).then(({data}) => {
            if(data && data.code === 0) {
               this.isReceive = data.dataRemind ? true : false
            }
        })
    },
    methods: {
        confirm() {
            this.$toast.loading({
                duration: 0,
                message: '保存中...',
                forbidClick: true,
            });
            if(this.isDesensitizer) {
                localStorage.setItem('isDesensitizer',1)
            }else {
                localStorage.setItem('isDesensitizer',0)
            }

            this.$http({
                url: this.$http.adornUrl('/wxapp/sys/user/openDataRemind'),
                method: 'GET',
                params: this.$http.adornParams({
                    status: this.isReceive ? 1 : 0
                })
            }).then(({data}) => {
                this.$toast.clear()
                if(data && data.code === 0) {
                    this.$toast.success({
                        message: '保存成功',
                        duration: 3000,
                    })
                }else {
                    this.$toast.fail(data.msg)
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.page {
    height: 100vh;
    background-color: #FFFFFF;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.item {
    padding:  24px 0;
    border-bottom: 1px solid #EDEDED;
}
.item-top {
    display: flex;
    flex-direction: column;
}
.item-top .title {
    font-size: 32px;
    font-weight: 600;
    color: rgba(0,0,0,0.85);
}
.item-top .tips {
    font-size: 32px;
    font-family: 'PingFangSC-Regular';
    font-weight: 400;
    color: #666666;
}
.item-mid {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 24px 0;
}
.item-mid .item-name {
    font-size: 32px;
    /* font-family: PingFangSC-Medium, PingFang SC; */
    font-weight: 500;
    color: rgba(0,0,0,0.85);
    margin-left: 28px;
}
.item-down {
    font-size: 28px;
    font-family: 'PingFangSC-Regular';
    font-weight: 400;
    color: #999999;
}
.button {
    width: 100%;
    margin-bottom: 40px;
    height: 88px;
    background: #4581F8;
    font-size: 32px;
    //   font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
}
</style>
